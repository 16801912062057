<template>
  <div>
    <b-modal
      v-model="isModalShow"
      size="xl"
      title=""
      @hide="setShowModalExport(false)"
    >
      <template v-slot:modal-title>
        <h4>Export Jobsheet</h4>
      </template>

      <div id="orderHeader" class="">
        <b-container fluid>
          <div id="orderHeaderCard">
            <div id="content">
              <b-row>
                <b-col cols="6" xl="2" class="">
                  <!-- <b-col cols="6" sm="6" lg="6" xl="12"> -->
                  <b-form-group
                    id="input-group-1"
                    label="ค้นหา"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="filter.search"
                      type="text"
                      placeholder="หมายเลขคำสั่งซื้อ,ชื่อ,นามสกุล,เบอร์โทรศัพท์"
                    ></b-form-input>
                  </b-form-group>
                  <!-- </b-col> -->
                </b-col>
                <b-col
                  class="item-align-end d-xl-none"
                  cols="6"
                  sm="6"
                  lg="6"
                  xl="12"
                >
                </b-col>
                <b-col cols="6" xl="2">
                  <b-form-group
                    id="input-group-2"
                    label="เลือกวันที่เริ่มต้น"
                    label-for="input-2"
                  >
                    <datetime
                      input-id="start-date"
                      placeholder="วัน/เดือน/ปี"
                      v-model="filter.startFilterDate"
                      format="dd/MM/yyyy"
                      class="theme-pink"
                      input-class="form-control"
                    >
                    </datetime>
                  </b-form-group>
                </b-col>
                <b-col cols="6" xl="2">
                  <b-form-group
                    id="input-group-3"
                    label="เลือกวันที่สิ้นสุด"
                    label-for="input-3"
                  >
                    <datetime
                      input-id="end-date"
                      placeholder="วัน/เดือน/ปี"
                      v-model="filter.endFilterDate"
                      format="dd/MM/yyyy"
                      class="theme-pink"
                      input-class="form-control"
                    >
                    </datetime>
                  </b-form-group>
                </b-col>
                <b-col class="" cols="6" xl="2">
                  <b-form-group
                    id="input-group-1"
                    label="สาขา"
                    label-for="input-1"
                  >
                    <b-form-select
                      v-model="branchSelected"
                      :options="branchLists"
                      class=""
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      ref="branch"
                      @change="onBranchSelected(branchSelected)"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="null" disabled
                          >-- กรุณาเลือกสาขา --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="px-1" cols="12" xl="1">
                  <b-button
                    variant="primary"
                    id="btnSearch"
                    class="w-100 bg-color-primary"
                    @click="handleSearch"
                    >ค้นหา</b-button
                  >
                </b-col>
                <b-col class="px-1" cols="12" xl="2">
                  <b-button
                    variant="secondary"
                    id="btnSearch"
                    class="w-100"
                    @click="onClearFilter"
                    >ล้างการค้นหา</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col>
                  <span
                    v-for="(item, index) in statusLists"
                    :key="index + 'status'"
                    class="pointer"
                    :class="{
                      'text-blue': statusId === item.id
                    }"
                    :id="'status_' + item.id"
                    @click="getStatusId(item.id)"
                  >
                    <span>{{ index > 0 ? "| " : "" }}</span>
                    <span class="text-nowrap"
                      >{{ item.name }} ({{ item.count }})
                    </span>
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div
                    id="order-table"
                    class="mt-2"
                    :class="{
                      'text-danger': [5, 11].includes(statusId)
                    }"
                  >
                    <b-table
                      id="order-list-table"
                      striped
                      hover
                      :items="orderlists"
                      :fields="fields"
                      :per-page="perPage"
                      :busy="isOrderListBusy"
                      ref="selectableOrder"
                      primary-key="orderId"
                      @row-clicked="myRowClickHandler"
                      show-empty
                      empty-text="ไม่พบข้อมูลที่ผู้ใช้กำลังค้นหาในฐานข้อมูล"
                      responsive
                    >
                      <template v-slot:head(ids)="data">
                        <b-form-checkbox
                          class="selectOrderBox"
                          v-model="selectedAll"
                          :ref="data.field.key"
                        ></b-form-checkbox>
                      </template>

                      <template v-slot:cell(ids)="data">
                        <b-checkbox
                          v-model="selected"
                          :value="data.item.orderId"
                          class="selectOrderBox"
                        ></b-checkbox>
                      </template>

                      <!-- <template v-slot:cell(invoiceNo)="row">
                        {{ row.item.invoiceNo }}
                      </template> -->

                      <template v-slot:cell(firstname)="row">
                        <span
                          :class="{
                            'text-dark': [5, 11].includes(statusId)
                          }"
                        >
                          <span v-if="row.item.firstname && row.item.lastname">
                            {{
                              row.item.firstname + "   " + row.item.lastname
                            }}</span
                          >
                          <span v-else> {{ "-" }}</span>
                        </span>
                      </template>

                      <template v-slot:cell(trackingNo)="row">
                        <!-- {{ row.item.trackingNo || "-" }} -->
                        <span
                          :class="{
                            'text-dark': [5, 11].includes(statusId)
                          }"
                        >
                          <span v-if="row.item.trackingNo">{{
                            row.item.trackingNo.replace("F_", "")
                          }}</span>
                          <span v-else>-</span>
                        </span>
                      </template>

                      <template v-slot:cell(grandTotal)="data">
                        <span
                          :class="{
                            'text-dark': [5, 11].includes(statusId)
                          }"
                        >
                          {{ data.item.grandTotal | numeral("0,0.00") }}
                        </span>
                      </template>

                      <template v-slot:cell(orderStatus)="data">
                        <span
                          :class="{
                            'text-dark': [5, 11].includes(statusId)
                          }"
                        >
                          <span
                            class="badge badge-success bg-dark"
                            v-if="data.item.orderStatus === 'จัดส่งเรียบร้อย'"
                            >{{ data.item.orderStatus }}</span
                          >
                          <span
                            class="badge badge-danger bg-success"
                            v-if="data.item.orderStatus === 'จัดเตรียมสินค้า'"
                            >{{ data.item.orderStatus }}</span
                          >
                          <span
                            class="badge badge-warning text-white bg-dark"
                            v-if="data.item.orderStatus === 'พิมพ์ Jobsheet'"
                            >{{ data.item.orderStatus }}</span
                          >
                        </span>
                      </template>

                      <template v-slot:cell(createdTime)="data">
                        <span
                          :class="{
                            'text-dark': [5, 11].includes(statusId)
                          }"
                        >
                          {{ data.item.createdTime | moment($formatDate) }}
                        </span>
                      </template>

                      <template v-slot:cell(action)="row">
                        <b-button
                          variant="secondary"
                          class="m-1 btn-circle download-jobsheet"
                          id="importButton"
                          v-c-tooltip="{
                            content: 'Download Shipping Label and Jobsheet',
                            placement: 'left-start'
                          }"
                          @click="onExportShippingLabel(row.item)"
                          :disabled="selected.length > 1"
                        >
                          <font-awesome-icon icon="file-download" />
                        </b-button>
                      </template>
                      <template v-slot:table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </b-col>
              </b-row>
            </div>

            <div id="pagination">
              <div class="">
                <b-pagination
                  v-model="filter.page"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="order-list-table"
                  align="center"
                  @change="pagination"
                ></b-pagination>
              </div>
            </div>
            <template v-if="forceRefresh"> </template>

            <b-modal id="modal-1" v-model="result" hide-footer hide-header>
              <div class="mb-1">{{ resultMessage }}</div>
              <small
                class="text-danger"
                v-for="(item, index) in errorLists"
                :key="index + 'error'"
              >
                {{ item }}
              </small>
            </b-modal>
          </div>
        </b-container>
      </div>

      <template v-slot:modal-footer>
        <div class="w-100 text-right">
          <button
            class="btn btn-secondary mr-1"
            @click="setShowModalExport(false)"
          >
            ยกเลิก
          </button>
          <button
            class="btn text-white bg-color-primary"
            id="importButton"
            :disabled="selected.length == 0"
            @click="onExportShippingLabel()"
          >
            Export ({{ selected.length }})
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="isShowModalOverLay"
      no-fade
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div class="text-center">
        <img
          src="@/assets/images/loading.svg"
          alt="loading"
          class="my-2 loading-icon"
        />
        <div class="textc-primary">กรุณารอสักครู่</div>
      </div>
    </b-modal>
    <b-modal
      v-model="isShowModalMessage"
      no-fade
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div class="text-center">
        <div class="textc-primary">{{ resultMessage }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// import { DateTime } from "luxon";
import * as moment from "moment/moment";
import { mapGetters } from "vuex";
export default {
  name: "ExportJobsheet",
  props: {
    isShow: {
      required: false,
      type: Boolean
    }
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    opacityStyle: function() {
      if (this.selectedAll) {
        return { opacity: 0.5 };
      } else {
        return { opacity: 1 };
      }
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      branchID: "getBranch"
    })
  },
  components: {
    datetime: Datetime
  },
  data() {
    return {
      filter: {
        startFilterDate: moment()
          .subtract(7, "days")
          .format(),
        endFilterDate: moment(Date.now()).format(),
        search: "",
        pageSize: 10,
        page: 1
      },
      branchLists: [],
      selected: [],
      currentDate: new Date(),
      body: {},
      orderSelected: {},
      orderlists: [],
      isOrderListBusy: true,
      timeOutBusyStatus: null,
      perPage: 10,
      rows: 0,
      resultMessage: "",
      branchId: null,
      isShowModalImportTracking: false,
      isShowModalAddTracking: false,
      isShowModalOverLay: false,
      isShowModalMessage: false,
      isShowModalNote: false,
      forceRefresh: true,
      statusLists: [],
      branchSelected: null,
      shippingLists: [],
      errorLists: [],
      statusId: 0,
      result: false,
      fields: [
        {
          key: "ids",
          label: ""
        },
        {
          key: "invoiceNo",
          label: "หมายเลขคำสั่งซื้อ",
          class: "text-center"
        },
        {
          key: "firstname",
          label: "ชื่อ-นามสกุล"
        },
        {
          key: "trackingNo",
          label: "หมายเลขพัสดุ",
          class: "text-center"
        },
        {
          key: "grandTotal",
          label: "ราคารวม",
          class: "text-right"
        },
        {
          key: "orderStatus",
          label: "สถานะ",
          class: "text-center"
        },
        {
          key: "createdTime",
          label: "วันที่",
          class: "text-center"
        },
        { key: "action", label: "ACTION", class: "text-center" }
      ],
      selectedAll: false,
      allItems: []
    };
  },
  created() {
    if (this.brandID) {
      this.getAllBrach(this.brandID);
      this.setFilter();

      // this.onSetConfig();
      // this.getShippingType();
      this.statusId = 0;
    }
    setTimeout(() => {
      this.$root.$emit("setConfig");
    }, 200);
  },

  watch: {
    selected: function() {
      if (this.selected.length === this.allItems.length) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    },
    selectedAll: function() {
      if (this.selected.length != this.allItems.length) {
        if (this.selectedAll) {
          this.selected = [];
          this.allItems.forEach(element => {
            this.selected.push(element.orderId);
          });
        }
      } else {
        if (!this.selectedAll) {
          this.selected = [];
        }
      }
    }
  },
  methods: {
    moment: function() {
      return moment();
    },
    setShowModalExport(value) {
      this.$emit("setShowModalExport", value);
    },
    onBranchSelected(value) {
      this.branchId = value;
    },
    getStatusId(statusId) {
      this.selected = [];
      this.statusId = statusId;
      this.selectedAll = false;

      // this.statusId = statusId;
      this.body = {
        search: this.filter.search,
        page: 1,
        pageSize: this.filter.pageSize,
        startFilterDate: new Date(this.filter.startFilterDate),
        endFilterDate: new Date(this.filter.endFilterDate),
        statusId: statusId
      };

      this.getOrderList();
      this.getAllData();
    },
    getAllBrach(brandId) {
      this.$axios
        .get(this.$baseUrl + "/api/brand/" + brandId + "/branch")
        .then(response => {
          if (response.data.result === 1) {
            if (response.data.detail.length !== 0) {
              this.branchLists = response.data.detail;
              this.branchId = response.data.detail[0].id;
              this.branchSelected = response.data.detail[0].id;
              this.getOrderList();
              this.getAllData();
            }
          }
        });
    },
    getOrderList() {
      this.$axios
        .post(
          this.$baseUrl +
            "/api/transaction/jobsheetList/" +
            this.brandID +
            "/branch/" +
            this.branchId,
          this.body
        )
        .then(response => {
          if (response.data.result === 1) {
            this.orderlists = response.data.detail.result;
            this.rows = response.data.detail.totalRecord;

            this.isOrderListBusy = false;
            this.statusLists = response.data.detail.status;
          }

          setTimeout(() => {
            this.$root.$emit("setConfig");
          }, 100);

          //console.log("fa", this.$cookie.get("favicon"));
          //this.$root.$emit("setConfig");
        });
    },
    onSetConfig() {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.$cookies.get("booth_favicon");
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    onDatePickerClose() {
      this.setFilter();
      this.selected = [];
      this.getOrderList();
      this.getAllData();
    },
    setFilter() {
      this.body = {
        search: this.filter.search,
        page: this.filter.page,
        pageSize: this.filter.pageSize,
        startFilterDate: moment(new Date(this.filter.startFilterDate)).format(),
        endFilterDate: moment(new Date(this.filter.endFilterDate)).format(),
        statusId: this.statusId
      };
    },
    setShowModalImportTracking(value) {
      this.handleForcefresh();
      this.selected = [];
      this.isShowModalImportTracking = value;
      if (!this.isShowModalImportTracking) {
        this.getOrderList();
      }
    },
    onClearFilter() {
      this.selectedAll = false;
      this.selected = [];
      this.filter.page = 1;
      this.statusId = 0;
      this.filter.search = "";
      (this.filter.startFilterDate = moment()
        .subtract(7, "days")
        .format()),
        (this.filter.endFilterDate = moment(Date.now()).format()),
        this.setFilter();
      this.getOrderList();
      this.getAllData();
    },
    setShowModalDisplayNote(value, item) {
      this.handleForcefresh();
      if (item) {
        this.orderSelected = item;
      } else {
        this.getOrderList();
        this.selected = [];
      }
      this.isShowModalNote = value;
    },
    setShowModalAddTracking(value, item) {
      this.handleForcefresh();
      if (item) {
        this.orderSelected = item;
        this.selected = [];
        this.selected.push(item.orderId);
      }
      this.isShowModalAddTracking = value;
      if (!this.isShowModalAddTracking) {
        this.getOrderList();
        this.selected = [];
      }
    },
    onFilterOrder(value) {
      this.setFilter();
      this.selected = [];
      this.filter.search = value;
      this.filter.page = 1;
      this.statusId = 0;
      //   this.getOrderList();
      //   this.getAllData();
    },
    pagination(page) {
      this.filter.page = page;
      this.setFilter();
      this.isOrderListBusy = true;
      this.getOrderList();
    },
    myRowClickHandler(item) {
      let target = this.selected.indexOf(item.orderId);
      if (target < 0) {
        this.selected.push(item.orderId);
      } else {
        this.selected.splice(target, 1);
      }
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    async uploadTracking(ShippingId, base64ExcelString) {
      this.isShowModalOverLay = true;
      this.$axios
        .put(this.$baseUrl + "/api/order/importTrackingNo", {
          ShippingId: ShippingId,
          base64ExcelString: base64ExcelString
        })
        .then(response => {
          if (response.data.result === 1) {
            this.setShowModalImportTracking(false);
            this.result = true;
            this.resultMessage = "อัพโหลดไฟล์สำเร็จ";
            this.errorLists = [];
            this.isShowModalOverLay = false;
          } else {
            this.result = true;
            this.resultMessage = response.data.message;
            this.errorLists = response.data.detail.error;
            this.isShowModalOverLay = false;
          }
        });
    },
    handleSearch() {
      this.filter.page = 1;
      this.selected = [];
      this.statusId = 0;
      this.setFilter();
      this.getOrderList();
      this.getAllData();
    },
    onExportJobSheet(item) {
      this.isShowModalOverLay = true;
      if (item) {
        this.selected = [];
        this.selected.push(item.orderId);
      }

      this.$axios
        .post(
          this.$baseUrl + "/api/transaction/export-shipping",
          this.selected,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          if (response.data.size !== 201) {
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data]),
              {
                type: "application/octet-stream"
              }
            );
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            if (item) {
              fileLink.setAttribute(
                "download",
                item.invoiceNo + "_JobSheet.pdf"
              );
            } else {
              fileLink.setAttribute(
                "download",
                this.currentDate + "_JobSheet.pdf"
              );
            }
            document.body.appendChild(fileLink);

            fileLink.click();
            this.selected = [];
            this.isShowModalOverLay = false;
          } else {
            alert("ไม่พบรายการขายบางรายการ กรุณาลองใหม่อีกครั้ง");
            this.isShowModalOverLay = false;
          }
        });
    },
    onExportShippingLabel(item) {
      this.isShowModalOverLay = true;
      if (item) {
        this.selected = [];
        this.selected.push(item.orderId);
      }

      this.$axios
        .post(
          this.$baseUrl + "/api/transaction/export-shipping",
          this.selected,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]), {
            type: "application/octet-stream"
          });
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          if (item) {
            fileLink.setAttribute(
              "download",
              item.invoiceNo + "_Shipping_Label.pdf"
            );
          } else {
            fileLink.setAttribute(
              "download",
              this.currentDate + "_Shipping_Label.pdf"
            );
          }
          this.isShowModalOverLay = false;
          document.body.appendChild(fileLink);
          fileLink.click();
          this.selected = [];
          this.getOrderList();
        })
        .catch(error => alert(error));
    },
    getShippingType() {
      this.$axios
        .get(this.$baseUrl + "/api/order/shippingList")
        .then(response => {
          if (response.data.result === 1) {
            this.shippingLists = response.data.detail;
          } else {
            alert(response.data.message);
          }
        });
    },
    getAllData() {
      let body = {
        search: this.filter.search,
        page: -1,
        startFilterDate: this.filter.startFilterDate,
        endFilterDate: this.filter.endFilterDate,
        statusId: this.statusId
      };

      this.$axios
        .post(
          this.$baseUrl +
            "/api/transaction/jobsheetList/" +
            this.brandID +
            "/branch/" +
            this.branchId,
          body
        )
        .then(response => {
          if (response.data.detail.result.length > 0) {
            this.allItems = response.data.detail.result;
          }
        });
    },
    onExportTemplate() {
      this.selected = [];
      this.$axios
        .get(this.$baseUrl + "/api/order/template", { responseType: "blob" })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]), {
            type: "application/vnd.ms-excel"
          });
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Import_Tracking_Template.xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();
          // document.body.removeChild(link);
        });
    }
  }
};
</script>

<style scoped>
#orderHeader {
  height: calc(100vh / 1.5);
  overflow-x: auto;
}
.text-blue {
  color: darkblue;
}
.download-jobsheet {
  color: white;
}

@media only screen and (min-width: 1200px) {
  #btnSearch {
    margin-top: 28px;
  }
}
@media (max-width: 1199px) {
  #btnSearch {
    margin-top: 10px;
  }
}
</style>
